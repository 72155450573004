<div class="main-container padding-skip-navbar">

    <h1>Aviso de Privacidad</h1>
    <h2>Hotel Clone</h2>
    <p>En Hotel Clone, nos comprometemos a proteger y respetar su privacidad. Este aviso de privacidad explica cómo recopilamos, utilizamos y protegemos sus datos personales cuando utiliza nuestros servicios.</p>
    
    <h3>1. Responsable del Tratamiento de sus Datos Personales</h3>
    <p>Hotel Clone, con domicilio en [dirección completa del hotel], es responsable del tratamiento de sus datos personales.</p>
    
    <h3>2. Datos Personales que Recopilamos</h3>
    <p>Podemos recopilar y tratar los siguientes datos personales:</p>
    <ul>
        <li>Nombre</li>
        <li>Dirección</li>
        <li>Número de teléfono</li>
        <li>Correo electrónico</li>
        <li>Información de pago</li>
        <li>Preferencias de estancia</li>
        <li>Información sobre sus visitas a nuestro sitio web y uso de nuestros servicios</li>
    </ul>
    
    <h3>3. Finalidades del Tratamiento de sus Datos Personales</h3>
    <p>Utilizamos sus datos personales para las siguientes finalidades:</p>
    <ul>
        <li>Proporcionar y gestionar nuestros servicios de alojamiento</li>
        <li>Procesar y confirmar reservas</li>
        <li>Gestionar pagos y facturación</li>
        <li>Enviar comunicaciones relacionadas con su estancia</li>
        <li>Mejorar nuestros servicios y su experiencia como cliente</li>
        <li>Cumplir con obligaciones legales y regulatorias</li>
    </ul>
    
    <h3>4. Transferencias de Datos Personales</h3>
    <p>Sus datos personales no serán compartidos con terceros, salvo en los casos necesarios para el cumplimiento de las finalidades descritas en este aviso de privacidad y en cumplimiento con la legislación aplicable.</p>
    
    <h3>5. Seguridad de sus Datos Personales</h3>
    <p>Implementamos medidas de seguridad técnicas y organizativas para proteger sus datos personales contra el acceso no autorizado, pérdida, alteración o divulgación.</p>
    
    <h3>6. Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)</h3>
    <p>Usted tiene derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales. Para ejercer estos derechos, puede contactarnos a través de [correo electrónico de contacto] o en nuestro domicilio.</p>
    
    <h3>7. Cambios en este Aviso de Privacidad</h3>
    <p>Nos reservamos el derecho de actualizar este aviso de privacidad en cualquier momento. Cualquier cambio será publicado en nuestro sitio web y, si es significativo, se lo notificaremos directamente.</p>
    
    <h3>8. Contacto</h3>
    <p>Si tiene alguna pregunta o inquietud sobre este aviso de privacidad o sobre el tratamiento de sus datos personales, puede contactarnos en:</p>
    <p>
        Hotel Clone<br>
        [dirección completa del hotel]<br>
        Teléfono: [número de teléfono]<br>
        Correo electrónico: [correo electrónico de contacto]
    </p>
</div>