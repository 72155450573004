<section class="main-container-admin-view">
    <div class="max-width-1400">
        <app-breadcrumb [links]="links"></app-breadcrumb>
        <button 
            *ngIf="action === 'detail'"
            class="btn btn-primary" (click)="editOffer()">Actualizar
        </button>
        <button 
            *ngIf="action === 'detail'"
            class="btn btn-danger ms-3" (click)="deleteOffer()">Eliminar
        </button>
        <form [formGroup]="form_offer">
        <div class="mb-3 mt-3">
            <label for="name" class="form-label">Nombre</label>
            {{action}}
            <input type="text" class="form-control" formControlName="name" placeholder="Nombre">
        </div>
        <div class="mb-3">
            <label for="description" class="form-label">Descripción</label>
            <textarea class="form-control" id="description" rows="3" formControlName="description"name="description"
             required placeholder="Descripción"></textarea>
        </div>

        <div class="mb-3" *ngIf="action === 'create'">
            <label for="file" class="form-label">Imagenes</label>
            <input type="file" class="form-control" id="file_input_add" (change)="onFileChange($event)" name="file" accept=".jpg,.png,.jpeg">
        </div>
        <div class="card col-6" *ngIf="image">
            <img [src]="image.filePath" alt="Room Image" *ngIf="action !== 'create'">

            <div class="container-trash-icon" (click)="deleteImage(image.id)">
                <i class="bi bi-trash"></i>
            </div>
        </div>
        
        <div class="w-100 mt-3">
            <button 
                type="submit" 
                *ngIf="action === 'create'"
                class="btn btn-primary" (click)="submitForm()" [disabled]="form_offer.invalid || files.length === 0">Crear
            </button>
            <button 
                type="submit" 
                *ngIf="action === 'edit'"
                class="btn btn-primary" (click)="updateOffer()" [disabled]="form_offer.invalid">Actualizar
            </button>
        </div>
            <div *ngIf="action === 'edit'" class="mt-4">

                <h4>Imagenes</h4>
                <div class="input-group mb-3">
                    <input type="file" class="form-control" (change)="onFileChange($event)" id="form_files_multiples" multiple accept=".jpg, .jpeg, .png" max="5">
                    <button type="button" class="btn btn-primary" (click)="addImages()">Guardar</button>
                  </div>
                <!-- <div class="grid">
                    <div *ngFor="let image of images" class="grid-item w-100">
                        <img [src]="image.filePath" alt="Room Image" class="img-grid-detail">
                        <div class="container-hover-delete" *ngIf="action === 'edit'">
                            <button type="button" class="btn btn-danger" (click)="deleteImage(image.id)">Eliminar</button>
                        </div>
                    </div>
                </div> -->
            </div> 
        </form>
    
    </div>
    
</section>
