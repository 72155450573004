<div class="parallax mb-5"></div>

<h2 class="mb-5 p-3 mt-4">Contamos con área de Restaurante Bar y con servicio a habitaciones de alimentos y bebidas durante las 24 horas del
    día.</h2>
<h1 class="mt-5">MENÚ DE ALIMENTOS</h1>
<img src="../../../assets/images/aster.png" class="wow animate__animated animate__fadeInLeft mb-3">
<div class="mineContainerAlimentos">
    <div *ngFor="let img of images">
        <img [src]="img.filePath" alt="" class="w-100 object-fit-cover">
    </div>
</div>