<section class="main-container-admin-view">
  <div class="">
    <ul>
      <li *ngFor="let section of sections" class="mb-3">
        {{ section.title}}

        <ul>
          <li *ngFor="let subsection of section.actions" class="subsection-li" (click)="goToSection(subsection.route)">{{subsection.name}}</li>
        </ul>
      </li>
    </ul>
  </div>
</section>
