<div class="parallax"></div>

<div class="mineContainerPromociones">
    <div class="row mt-5 pb-4" *ngFor="let offer of offers">
        <div class="col-lg-6 col-md-12"> <img [src]="offer.imagePath"
                class="w-100 wow animate__animated animate__fadeInLeft img-250">
        </div>

        <div class="col-lg-6 col-md-12">
            <h2>{{offer.name}}</h2>
            <img src="../../../assets/images/aster.png" class="w-100 wow animate__animated animate__fadeInLeft">
            <p class="mt-3 pt-3 pb-3 fp">
                {{offer.description}}
            </p>
        </div>
    </div>
</div>