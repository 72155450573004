import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-icon-overlay',
  templateUrl: './loading-icon-overlay.component.html',
  styleUrls: ['./loading-icon-overlay.component.css']
})
export class LoadingIconOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
