<div class="carousel slide carousel-fade h-770" data-ride="carousel">
    <carousel class="w-100 h-100 mt-5">
        <slide *ngFor="let img of images_carousel">
            <img [src]="img" alt="first slide" class="img-slider-carousel">
        </slide>
    </carousel>
</div>

<div class="mineContainer">
    <div class="row">
        <div class="caja col-md-8 col-12 pt-2 pb-4 mb-4">
            <article>
                <p>El Hotel Clone presenta un diseño único y divertido, inspirado en una mezcla de letras y símbolos, convirtiéndolo en el lugar perfecto para disfrutar en pareja. Nuestras habitaciones están diseñadas para estimular la imaginación y hacer de su estancia una experiencia inolvidable.</p>
                <p>Nuestra misión es proporcionar todos los servicios y comodidades necesarios para que vivas momentos excepcionales. No esperes más y atrévete a ser diferente con nosotros.</p>
            </article>
        </div>
        <div class="col-md-4 col-12 d-flex align-items-center justify-content-center">
            <div id="logo-home">
                <h1>
                    <a [routerLink]='["/"]'><span class="h-letter">H</span>otel <span class="clone-text">clone</span></a>
                </h1>
            </div>
        </div>
    
    </div>
    <div class="row">    
        <div class="col-12 mt-5">
            <a class="btn1 float-left w-100 text-center col-lg-3" [routerLink]='["/aviso-de-privacidad"]'>AVISO DE PRIVACIDAD</a></div>
    </div>

</div>