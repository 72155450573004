<div class="parallax"></div>

<div class="a pb-4 pt-4">
    <div class="mineContainer">
        <div class="row" *ngFor="let item of rooms">
            <div class="col-12 col-lg-6">
                <carousel class="w-100 h-100 mt-5">
                    <slide *ngFor="let img of item.images">
                        <img [src]="img" alt="first slide" class="img-250">
                    </slide>
                </carousel>                
            </div>
            <div class="col-12 col-lg-6">
                <h1 class="mt-2">{{item.name}}</h1>
                <img src="../../../assets/images/aster.png"  class="wow animate__animated animate__fadeInLeft mb-3"   >
                <p>{{item.description}}</p>
                <ul>
                    <li>{{item.characteristics}}</li>
                </ul>
            </div>
    
        </div>
    </div>
   

</div>