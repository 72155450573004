
<div class="parallax mb-5"></div>
<div class="gap">
    <div class="row">
        <div class="col-12">
            <h3>VIDEO</h3>
            <hr class="hrs">
            <img src="../../../assets/images/aster.png" class="wow animate__animated animate__fadeInLeft mb-3">

        </div>
    </div>
</div>
<div class="two">
    <div class="mineContainer">

        <div class="row">
    
            <div class="col-12">
                <div> 
                    <video controls preload="metadata" class="video w-100">
                        <source src="../../../assets/videos/video-gallery.mp4" type="video/webm"/>
                        Video not supported.
                    </video>
                </div>
    
    
            </div>
    
        </div>
    </div>
    
    
</div>
<div class="gap">
    <div class="row">
        <div class="col-12">
            <h3 class="h32">GALERIA FOTOGRÁFICA</h3>
            <hr class="hrs">
            <img src="../../../assets/images/aster.png" class="wow animate__animated animate__fadeInLeft mb-3">

        </div>
    </div>
</div>

<div class="galeria">
    <a *ngFor="let item of images" data-lightbox="hotel"  [attr.href]="item.filePath"><img [src]="item.filePath" alt=""></a>
</div>