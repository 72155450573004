
<section class="main-container-login">
  <form [formGroup]="applyForm" (submit)="submitApplication()">
    <div class="user-container">
      <label for="username">Nombre de usuario:</label>
      <input type="text" id="username" formControlName="username">
    </div>
    <div class="password-container">
      <label for="password">Contraseña:</label>
      <input type="password" id="password" formControlName="password">
    </div>
    <div>
      <input type="submit" value="Iniciar sesión">
    </div>
    <div class="alert alert-danger mt-2" role="alert" *ngIf="show_error_msg">
      Hubo un error al iniciar sesión, la contraseña o usuario es incorrecto
    </div>
  </form>
</section>
