<nav id="menu" *ngIf="verifyRoute()">
    <div id="logo">
      <h1>
        <a [routerLink]='["/"]'><span class="h-letter">H</span>otel <span class="clone-text">clone</span></a>
      </h1>
    </div>
    <ul>

      <li *ngFor="let item of navigation_items" [ngClass]="item.name">
        <a [routerLink]='[item.route]' [routerLinkActive]="['actived']">{{item.name}}</a>
        <ul class="submenu" *ngIf="item.name === 'SERVICIOS'">
          <li> <a [routerLink]='["servicios/alimentos-y-bebidas"]' [routerLinkActive]="['actived']" class="subItem">ALIMENTOS Y BEBIDAS</a></li>
          <!-- <li> <a [routerLink]='["servicios/menu-sex"]' [routerLinkActive]="['actived']" class="subItem">MENU SEX</a></li> -->
        </ul>
      </li>
      
    </ul>
    <div class="sidebar-responsive" [ngClass]="handle_class">
      <i class="bi bi-x-circle-fill close-icon" (click)="handle_class = 'd-none'"></i>
      <ul>        
        <li *ngFor="let item of navigation_items">
          <a (click)="handleClickRouteResponsive(item.route)" [routerLinkActive]="['actived']" *ngIf="item.name !== 'SERVICIOS'" class="subItem"> {{item.name}}</a>
          <div class="d-flex flex-column">

            <a (click)="handleClickRouteResponsive('servicios/alimentos-y-bebidas')" *ngIf="item.name === 'SERVICIOS'" [routerLinkActive]="['actived']" class="subItem">ALIMENTOS Y BEBIDAS</a>
            <a (click)="handleClickRouteResponsive('servicios/menu-sex')" *ngIf="item.name === 'SERVICIOS'" [routerLinkActive]="['actived']" class="subItem d-block mt-45">MENU SEX</a>
          </div>
        </li>
      </ul>
      
    </div>
    
    <i class="bi bi-list item-toggle-navbar" (click)="toggleSidebar()"></i>
</nav>
<nav *ngIf="!verifyRoute()" class="admin-view">
  <div class="d-flex justify-content-between align-items-center w-100 px-5 h-100">
    <h3 class="cursor-pointer" [routerLink]='["/"]'>Hotel Clone</h3>
    
    <div class="dropdown">
      <div class="d-flex align-items-center cursor-pointer" *ngIf="hasSession()"
       id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
      > Admin 

        <button type="button" class="btn btn-light ms-3" (click)="logout()">Cerrar sesión</button>

      </div>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" href="#">Cerrar sesión</a></li>
      </ul>
    </div>
  </div>
</nav>