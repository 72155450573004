<header>
  <app-navbar></app-navbar>
</header>

<section id="content-app-view">
  <router-outlet></router-outlet>
</section>

<footer>
  <app-footer></app-footer>
</footer>