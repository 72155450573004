<div class="parallax"></div>

<div class="mineContainerContact">
    <div class="row">
        <div class="col-lg-3 col-sm-12 mt-5">
            <h1 class="mb-4">CONTACTO</h1>
            <h3>Dirección:</h3>
            <p>Avenida Falsa #123. Colonia Inventada</p>
            <p>Ciudad Imaginaria, Estado Ficticio</p>
            <p>País de la Fantasía</p>
            <hr>
            <h3>TELEFONOS:</h3>
            <p>1234567890 / 0987654321</p>
            <p>1122334455</p>
            <hr>
            <h3>CÓMO LLEGAR:</h3>
            <p>Nos encontramos en una calle paralela a la Avenida Imaginaria, justo entre las calles de los sueños y la fantasía.</p>
            
        </div>
        <div class="col-lg-9 col-sm-12 mt-5 mb-5">
            <iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240864.19465529133!2d-99.30842114732549!3d19.390659367389866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0026db097507%3A0x54061076265ee841!2sCiudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1720800429215!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

    </div>
    <h2>Si tienes duda o comentario contactanos!</h2>
    <form [formGroup]="form_contact" (ngSubmit)="submitForm()" class="mt-4 mb-5">

        <div class="alert" role="alert">
            <strong>El formulario fue enviado con éxito</strong> 
            <button type="button" class="close" data-dismiss="alert" ><span>&times;</span></button>
        </div>

        <div>
            <label for="nombre">Su nombre</label>
            <input type="text" name="name" formControlName="name" required>
            <!-- <span class='error_validate' *ngIf="!name.valid && name.touched">Requerido</span> -->

        </div>
        <div class="miEmail"> <label for="email">Email</label>
            <i class="fas fa-envelope correo"></i>
            <input type="email" name="email" formControlName="email" required>
            <!-- <span class='error_validate' *ngIf="!email.valid && email.touched">Requerido</span> -->

        </div>
        <div>
            <label for="asunto">Asunto</label>
            <input type="text" name="subject" formControlName="subject" required>
            <!-- <span class='error_validate' *ngIf="!subject.valid && subject.touched">Requerido</span> -->

        </div>

        <div>
            <label for="mensaje">Su mensaje</label>
            <textarea name="message" formControlName="message" required cols="30"
                rows="10"></textarea>
            <!-- <span class='error_validate' *ngIf="!message.valid && message.touched">Requerido</span> -->

        </div>
        <input type="submit" [disabled]='form_contact.invalid'>

    </form>

</div>