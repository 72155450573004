<section class="main-container-admin-view">

  <app-breadcrumb [links]="links"></app-breadcrumb>

  <div class="w-1400">
    <div *ngIf="type !== 'room'">
      <label for="form_files_multiples" class="form-label">Agregar imagenes</label>
      <div class="input-group mb-3">
        <input type="file" class="form-control" (change)="onFileSelected($event)" id="form_files_multiples" multiple accept=".jpg, .jpeg, .png" max="5">
        <button type="button" class="btn btn-primary" (click)="saveImages()">Guardar</button>
      </div>
    </div>
    <div class="grid-images">
      <div class="grid-item position-relative" *ngFor="let img of images">
        <img [src]="img.filePath" alt="" class="w-100">

        <div class="container-hover-delete">
          <button type="button" class="btn btn-danger" (click)="openModal(template, img.id)">Eliminar</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #template>
    <div class="modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>¿Estás seguro de que quieres eliminar esta imagen?</p>
          <button type="button" class="btn btn-danger" (click)="confirm()">Sí, la quiero eliminar.</button>
          <button type="button" class="btn btn-secondary ms-3" (click)="decline()">No</button>
        </div>
      </div>
    </div>
  </ng-template>
</section>
