<div class="footerContent">
  <div class="d-flex w-100 justify-content-around py-5 flex-wrap">
    <div class="t">
      <p>John Doe #123, Col. Example Neighborhood.</p>
      <p>Fictitious City, State of Imagination.</p>
      <p>Email: contact@fakemail.com</p>
      <p class="m-0">Tels: 1234567890 / 0987654321 / 1122334455</p>        
    </div>
    <div id="logo" class="mt-5">
      <h1>
        <a [routerLink]='["/"]'><span class="h-letter">H</span>otel <span class="clone-text">clone</span></a>
      </h1>
    </div>
  </div>
  <div class="copy">
    &copy; Todos los derechos reservados para Cristian Ordoñez
  </div>
</div>
